<template>
    <div>
        <errors-bag-list/>

        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col cols="6">
                    <b-button variant='success' :to='routerSalaryRatesCreate()'>
                        <b-icon-plus scale="1.2"/>
                    </b-button>
                </b-col>
                <b-col cols="6" class="text-right">
                    <b-button variant='primary' @click='fetchSalarySettingRates()'>
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-table
            :items="preparedItems"
            :fields="fields"
            caption-top
            responsive
            head-variant="dark"
        >
            <template #table-caption>
                Должность: {{ salary.work_position.name }}
            </template>
            <template #cell(actions)="data">
                <b-button-group>
                    <b-button variant="warning" size="sm" :to='routerSalaryRatesEdit( data.item )'>
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button variant="danger" size="sm" @click='onDelete( data.item )' v-if='data.item.actions'>
                        <b-icon-trash-fill/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
    </div>
</template>

<script>
    import moment from 'moment';

    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import ErrorsBag from '@utils/errorsBag';
    import { SALARY_ENDPOINT } from '@utils/endpoints';

    const DATE_FORMAT = 'YYYY-MM-DD';

    export default {
        props: [ 'id' ],
        name: "SalaryRatesList",
        components: { ErrorsBagList },
        data() {
            return {
                ErrorsBag,

                rates: [],
                salary: {
                    work_position: {},
                },

                fields: [
                    { key: 'rate_per_hour', label: 'Стоимость часа, руб.' },
                    {
                        key: 'valid_from',
                        label: 'Активна с',
                        formatter(value) {
                            return value ? moment(value).format('DD.MM.YYYY') : null;
                        }
                    },
                    { key: 'actions', label: 'Действия' },
                ]
            };
        },
        computed: {
            preparedItems() {
                const preparedItems = [];

                this.rates.forEach((rate) => {
                    preparedItems.push({
                        id: rate.id,
                        rate_per_hour: rate.rate_per_hour,
                        valid_from: moment(rate.valid_from).format(DATE_FORMAT),
                        actions: moment(rate.valid_from).isAfter(moment().toISOString()),
                    });
                })

                return preparedItems;
            }
        },
        methods: {
            routerSalaryRatesCreate() {
                return { name: 'SalaryRatesCreate', params: { id: this.id } };
            },
            routerSalaryRatesEdit( record ) {
                return { name: 'SalaryRatesEdit', params: { id: this.id, rate_id: record.id } };
            },
            onDelete(record) {
                ErrorsBag.discard();

                this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить промежуток стоимости?', {
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    cancelVariant: 'success',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        this.$http
                            .delete(SALARY_ENDPOINT + '/rates/' + record.id)
                            .then(() => {
                                this.fetchSalarySettingRates();
                                this.$bvToast.toast('Удаление промежутка стоимости выполнено успешно', {variant: 'success'});
                            }, response => ErrorsBag.fill(response.body));
                    }
                });
            },
            fetchSalarySetting() {
                ErrorsBag.discard();

                let params = {
                    with: [ 'work_position' ]
                };

                return this.$http
                    .get( SALARY_ENDPOINT + '/settings/' + this.id, { params } )
                    .then( response => this.salary = response.data.data, response => ErrorsBag.fill( response.body ) );
            },
            fetchSalarySettingRates( discard = true ) {
                if ( discard ) {
                    ErrorsBag.discard();
                }

                this.rates = [];

                let params = {
                    filters: {
                        work_position: { id: this.salary.work_position_id }
                    },
                    orders: {
                        valid_from: 'desc'
                    }
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/rates', { params } )
                    .then(
                        response => this.rates = response.data.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            },
        },
        mounted() {
            this.fetchSalarySetting()
                .then( () => this.fetchSalarySettingRates( false ) );
        }
    }
</script>
